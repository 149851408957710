<template>
  <div>
    <md-menu md-size="auto" md-align-trigger>
      <md-badge :md-content="notifications.length" md-menu-trigger>
        <md-button   class="md-icon-button">
          <md-icon>notifications</md-icon>
        </md-button>
      </md-badge>

      <md-menu-content class="max-width-none">
        <md-list v-if="notifications.length == 0">
          <md-list-item>
            <md-icon>notifications_none</md-icon>
            <div class="md-list-item-text">Everithing up to date!</div>
            
          </md-list-item>
          <md-list-item>
            <md-button class="md-raised full-width-button md-primary" md-dense>View notifications</md-button>
          </md-list-item>
          
        </md-list>
        
        <md-list 
          class="md-double-line"
          v-else-if="notifications.length > 0"
        >
          <md-list-item v-for="notification in notifications" v-bind:key="notification.id">
            
          
              <md-icon class="md-primary" v-if="notification.type === 'user-linking'">
                people_alt
              </md-icon>
              <md-icon class="md-primary" v-if="notification.type === 'reservation'">
                confirmation_number
              </md-icon>
          
            

            <div class="md-list-item-text">
              <span class="mb-2">{{notification.message}}</span>
              <span>{{notification.user.email}} ({{notification.user.code}})</span>
              <span class="up-text-secondary">{{notification.typeLabel}} - {{ parseInt(notification.created_at) | moment("YYYY-MM-DD hh:mm A (HH:mm [HS])") }}</span>
              <md-divider class="mt-2 mb-2"></md-divider>
              
              
            </div>
          </md-list-item>
          <md-list-item>
            <md-button class="md-raised full-width-button md-primary" md-dense>View notifications</md-button>
          </md-list-item>
        </md-list>
        
      </md-menu-content>
    </md-menu>
      
    <md-menu md-size="medium" md-align-trigger >
      
      <md-button class="md-icon-button" md-menu-trigger>
        <md-icon>account_circle</md-icon>
      </md-button>
  
      <md-menu-content v-if="user.data" class="max-height-initial">
        <md-list class="md-double-line md-dense">
          <md-list-item class="min-height-auto">
            <div class="md-body-2">Email &amp; role</div>
          </md-list-item>
          <md-list-item>
            <div class="md-list-item-text">
              <span>{{ user.data.email }}</span>
              <span class="role mt-2 mb-3">{{ user.data.activeSubrole }} {{ user.data.activeRole }}</span>
            </div>
          </md-list-item>
          <md-divider></md-divider>
          <md-list-item class="min-height-auto mt-2" v-if="user.data.activeRole !== 'super-admin'">
            <div class="md-body-2">Unique user code</div>
          </md-list-item>
          <md-list-item v-if="user.data.activeRole !== 'super-admin'">
            <div class="md-list-item-text">  
              <span class="code mb-3">{{ user.data.code }}</span>
            </div>
          </md-list-item>
          <md-list-item>
            <md-button @click="userSettings" class="md-raised md-primary full-width-button">User settings</md-button>
          </md-list-item>
          <md-divider></md-divider>
          <md-list-item @click="this.$root.$children[0].signOut"
            >
            <md-icon class="mr-3 ">logout</md-icon>
            <span class="md-list-item-text">Logout</span>
              
              
              
          </md-list-item
          >
        </md-list>
      </md-menu-content>
    </md-menu>
    
    <md-menu md-suze="medium" md-align-trigger class="d-md-none">
      <md-button class="md-icon-button" md-menu-trigger>
        <md-icon>language</md-icon>
      </md-button>
      <md-menu-content>
        <md-list>
          <md-list-item>
            <md-icon>map</md-icon>
            <span class="md-list-item-text">
                <span class="up-chip  up-text-confirmed mb-1 white-space-no-wrap mr-3">TZ {{ timezone }}</span>
                <span class="up-chip up-text-secondary mr-3">UTC {{ parseInt(utc) > 0 ? '+' : ''}}{{ parseInt(utc) / 60 }}</span>
            </span>
          </md-list-item>
          <md-divider class="mt-2 mb-2"></md-divider>
          <md-list-item>
            <md-icon>query_builder</md-icon>
            <span class="md-list-item-text">
                <div class="white-space-no-wrap up-text-primary">              
                  {{ unix | moment('timezone', timezone , 'dddd, MMM Do YYYY') }}           
                </div>
                <div class="white-space-no-wrap up-text-primary"> 
                      {{ unix | moment('timezone', timezone , 'hh:mm A (HH:mm [HS])') }}
                </div>
            </span>
          </md-list-item>
        </md-list>
      </md-menu-content>
    </md-menu>
  </div>
</template>

<script>
//import { threadId } from 'worker_threads';
import firebase from './../Firebase'
import moment from 'moment'
import moment_timezone from "moment-timezone";

export default {
  name: "TheHeaderDropdownAccnt",
  // data () {
  //   return {
  //     itemsCount: 42
  //   }
  // },
  data() {
    return {
      notifications: [],
      total: null,
      isBusy: false,
      unix: null,
      week: ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'],
      timerId: null,
      localTimeZone: null,
    }
  },
  mounted() {
    console.log('this userrrrrr', this.user)
    var collection = this.getCollectionPath(this.user)
    collection.orderBy('created_at','desc').onSnapshot((querySnapshot) => {
      this.notifications = [];
       querySnapshot.forEach((doc) => {

         let dataToSave = doc.data();
         dataToSave.id = doc.id 
         
         this.notifications.push(dataToSave);
         

       });
      this.total = this.notifications.length;
       
      this.isBusy = false;
      if(this.total > 0) {
        this.notify()
      }
      
    });
  },
  created() {
    var zone_name =  moment.tz.guess();
    console.log('zone_name TheHeader.vue')
    console.log(zone_name)
    this.localTimeZone = zone_name 
    this.updateTime()
    setInterval(() => {
      this.updateTime()
    }, 1000)
  },
  methods: {
    updateTime() {
      var cd = new Date();
      this.unix = parseInt(moment().format('x'))
      
    },
    userSettings() {
      this.$router.push({path: `/app/user-settings`})
    },
    notify() {
      if(this.$notification) {

        for (let i = 0; i < this.notifications.length; i++) {
          if(!this.notifications[i].readed) {
              this.$notification.show(this.notifications[i].message, {
                body: this.notifications[i].typeLabel,
                
              }, {})
  
              var collection = this.getCollectionPath(this.user)
              this.notifications[i].readed = true
              collection.doc(this.notifications[i].id).update(this.notifications[i])
  
          }
          
          
        }
      }
      
    },
    getCollectionPath (user) {
      return firebase.firestore().collection('users').doc(this.user.data.uid).collection('notifications')
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    timezone() {
      return this.$store.state.timezone;
    },
    utc() {
      return this.$store.state.utc;
    },
    date() {
      return this.$store.state.date;
    },
    time() {
      return this.$store.state.time;
    },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
